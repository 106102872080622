import { BLOCKS } from "@contentful/rich-text-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import SEO from "../components/common/SEO";
import { H3, P } from "../components/common/Typography";
import { MEDIA_QUERIES } from "../config";

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <P>{children}</P>;
    },
  },
};

function Person({ data, pageContext, location }) {
  const person = data.contentfulPeople;

  return (
    <Layout>
      <SEO title={person.name} />

      <Section
        as="main"
        css={`
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 32px;
          padding: 72px 0;

          ${MEDIA_QUERIES.mobile} {
            grid-template-columns: 1fr;
            padding: 16px;
          }
        `}
      >
        <Img
          css={`
            max-width: 238px;
            height: 282px;
          `}
          fixed={person.image?.fixed}
        />
        <div>
          <H3>{person.name}</H3>
          <p
            css={`
              margin-bottom: 16px;
            `}
          >
            {person.bio}
          </p>
          {person.cv && renderRichText(person.cv, options)}
        </div>
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query PersonById($id: String!) {
    contentfulPeople(contentful_id: { eq: $id }) {
      bio
      contentful_id
      name
      image {
        fixed(width: 476) {
          src
          width
        }
      }
      cv {
        raw
      }
    }
  }
`;

export default Person;
